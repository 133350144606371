import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { Button } from '@makeship/core';
import { H1, H4Styles } from '../../../Typography';
import config from '../../../../../config.json';
import { getProductIDFromShopifyGID, hasProductTag } from '../../../../utils/product';
import { ProductTag } from '../../../../types/common';
import { isUserLoggedIn } from '../../../../utils/analytics';
import { useStore } from '../../../../store';

const { routes } = config;

const Heading = styled(H1)`
  color: ${({ theme }) => theme.colors.neutral1};
  text-transform: uppercase;
  white-space: nowrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${H4Styles}
    color: ${({ theme }) => theme.colors.neutral1};
  }
`;
const GreenText = styled(H1)`
  color: #94e59e;
  text-transform: uppercase;
  white-space: nowrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    ${H4Styles}
    color: #94E59E;
  }
`;

const MastheadWrapper = styled.div`
  height: 560px;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.linkHover};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 450px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column-reverse;
    height: 650px;
    justify-content: center;
    padding: 4px;
  }
`;

const Left = styled.div`
  margin-left: 10%;
  flex-basis: 35%;
  flex-shrink: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-left: 64px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-bottom: 48px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const MastheadText = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.neutral1};
  margin-top: 24px;
  margin-bottom: 40px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 18px;
  }
`;

const Right = styled.div`
  cursor: pointer;
  flex-basis: 65%;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;

  padding: 24px 64px 64px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-right: 32px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0;
    width: 100%;
    max-height: 300px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ImageWrapperDesktop = styled(ImageWrapper)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const ImageWrapperMobile = styled(ImageWrapper)`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

type MastheadProps = {
  masthead: Shopify.Collection | null;
  manualMasthead: Shopify.Collection | null;
};

const Masthead: React.FC<MastheadProps> = ({ masthead, manualMasthead }: MastheadProps) => {
  const { state } = useStore();
  const INDEX_NOT_FOUND = -1;

  const findIndexOfFirstVisibleMastheadProductFromCollection = (collection: Shopify.Collection | null) => {
    if (collection === null) return INDEX_NOT_FOUND;
    const products = collection.products.edges;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < products.length; i++) {
      if (!hasProductTag(products[i].node.tags, ProductTag.HideFromMasthead)) {
        return i;
      }
    }
    return INDEX_NOT_FOUND;
  };

  // if the manual masthead collection is empty or is not live
  // and if there are no non-hidden products, use the automated masthead collection
  let chosenMasthead = manualMasthead;
  if (
    !chosenMasthead?.products?.edges[0] ||
    !hasProductTag(chosenMasthead?.products?.edges[0].node.tags, ProductTag.Live) ||
    findIndexOfFirstVisibleMastheadProductFromCollection(chosenMasthead) === INDEX_NOT_FOUND
  ) {
    chosenMasthead = masthead;
  }

  const indexOfFirstVisibleMastheadProduct = findIndexOfFirstVisibleMastheadProductFromCollection(chosenMasthead);

  return (
    <MastheadWrapper>
      <Left>
        {chosenMasthead && (
          <>
            <Heading>
              Designed By
              <br />
              Creators.
              <br />
              <span>
                <GreenText>Fueled by fans.</GreenText>
              </span>
            </Heading>
            <MastheadText>{chosenMasthead.description}</MastheadText>
          </>
        )}
        <Link href={routes.shopLive} passHref>
          <Button.Tertiary
            onClick={() =>
              posthog.capture('masthead_cta_click', {
                location: 'masthead',
                is_logged_in: isUserLoggedIn(state.user),
              })
            }
            data-testid="masthead-link-button"
          >
            Explore Campaigns
          </Button.Tertiary>
        </Link>
      </Left>
      {chosenMasthead && (
        <Right
          onClick={() =>
            posthog.capture('masthead_image_click', {
              product_title: chosenMasthead
                ? chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct]?.node.title
                : '',
              product_id: getProductIDFromShopifyGID(
                chosenMasthead ? chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct]?.node.id : '',
              ),
              is_logged_in: isUserLoggedIn(state.user),
            })
          }
        >
          <Link
            href={`/products/${chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct]?.node.handle}`}
            passHref
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a data-testid="masthead-image">
              <ImageWrapperDesktop>
                <Image
                  src={
                    chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct].node.images.edges[3]?.node
                      .transformedSrc || chosenMasthead.image?.transformedSrc
                  }
                  alt={
                    chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct].node.images.edges[3]?.node
                      .altText ||
                    chosenMasthead.image?.altText ||
                    'masthead image'
                  }
                  layout="fill"
                  objectFit="contain"
                  quality={90}
                  priority
                />
              </ImageWrapperDesktop>
              <ImageWrapperMobile>
                <Image
                  src={
                    chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct].node.images.edges[0]?.node
                      .transformedSrc || chosenMasthead.image?.transformedSrc
                  }
                  alt={
                    chosenMasthead.products?.edges[indexOfFirstVisibleMastheadProduct].node.images.edges[0]?.node
                      .altText ||
                    chosenMasthead.image?.altText ||
                    'masthead image'
                  }
                  layout="fill"
                  objectFit="contain"
                  quality={90}
                  priority
                />
              </ImageWrapperMobile>
            </a>
          </Link>
        </Right>
      )}
    </MastheadWrapper>
  );
};

export default Masthead;
